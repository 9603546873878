import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/_global/layout/Layout";
import localize from "../components/_global/localization/localize";
import Section from "../components/_global/layout/Section";
import AltImage from "../components/_global/AltImage";
import Text from "../components/_global/editorial/Text";
import Spacer from "../components/_global/layout/Spacer";
import Block from "../components/_global/editorial/Block";
import RoundButton from "../components/_global/RoundButton";
import { useLanguageContext } from "../contexts/LanguageContext";

const HeaderImage = styled(AltImage)`
  max-height: 30rem;
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: top center;
`;

const DateCont = styled(Text)`
  align-self: center;
`;

const HorizLine = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.main01};
`;

const Event = ({ data, ...props }) => {
	const { file, agenda, general } = data;
	const date = new Date(file.date);
	const language = useLanguageContext();
	const day = file.dateType !== "month" ? { day: "numeric" } : null;
	const time = file.dateType === "dayTime" ? { hour: "numeric", minute: "numeric" } : null;

	// console.log(file.dateType);

	// console.log();
	const dateFormatter = new Intl.DateTimeFormat(
		language.lang,
		{
			month: "long",
			...day,
			year: "numeric",
			...time
		});

	return (
		<Layout title={agenda.common.title}>
			<Section m="large">
				<HeaderImage data={file.image} />
				<Spacer s="medium" />

				{file.dateType !== "none" ? <DateCont s="medium" c="main01">
					{dateFormatter.format(date)}
				</DateCont> : null}
				<Spacer s="medium" />

				<Text s="h2" c="main01">
					{file.title}
				</Text>
				<Spacer s="small" />

				{file.content ? <Block data={file.content} /> : null}
				<Spacer s="medium" />

				{file.eventLink ?
					<>
						<RoundButton style={{ alignSelf: "center" }} type="pill" data={file.eventLink} />
					</>
					: null}
				{/*<Link to={"/"}>*/}
				{/*	<PillButton type="pill" style={{ width: "fit-content" }}>*/}
				{/*		{general.registerForEvent}*/}
				{/*	</PillButton>*/}
				{/*</Link>*/}
				<Spacer s="medium" />
				<Spacer s="small" />

				<HorizLine />
				<Spacer s="large" />
			</Section>
			<Spacer s="medium" />

			<RoundButton style={{ alignSelf: "center" }} type="pill" data={general.backToAgenda} />
			<Spacer s="large" />
			<Spacer s="medium" />
		</Layout>
	);
};

export default localize(Event);

export const query = graphql`
    fragment EventFragment on SanityEvent {
        slug {
            current
        }
        dateType,
        date
        eventLink {
            ...LinkFragment
        }
        content {
            ...CompleteBlockFragment
        }
        description {
            _type
            fr
        }
        image {
            ...ImageFragment
        }
        title {
            _type
            fr
        }
    }

    query($_id: String!) {
        agenda: sanityAgenda {
            common {
                link
                title {
                    _type
                    fr
                }
            }
        }
        general: sanityGeneralComponent {
            registerForEvent {
                _type
                fr
            }
            backToAgenda {
                ...LinkFragment
            }
        }
        file: sanityEvent( _id: { eq: $_id } ) {
            ...EventFragment
        }
    }
    #    query LearnFileQuery {
    #        sanityLearnFile(_id: {eq: "343da410-0774-4a8e-b19a-2aa1399adc37"}) {
    #            _id
    #        }
    #    }
`;
